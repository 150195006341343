import { cn } from '@/util/cn'
import { useState } from 'react'

type ToggleProps = {
    className?: string
    options: string[]
    defaultIndex: number
    onClick: (index: number) => void
    // use this if you need to navigate during the transition
    // if you try to update the url during an animation, it will cancel the animation
    onTransitionEnd?: (index: number) => void
}
const Toggle = ({ className, options, defaultIndex, onClick, onTransitionEnd }: ToggleProps) => {
    const [index, setIndex] = useState(defaultIndex)
    const buttonWidth = `${100 * (1 / options.length)}%`
    const left = `${100 * (index / options.length)}%`
    const gradient = ['bg-gradient-primary', 'bg-gradient-secondary', 'bg-gradient-tertiary']
    return (
        <div
            className={cn(
                'bg-gradient-grey text-giphyWhite relative flex justify-between rounded-3xl font-bold md:w-[400px]',
                className
            )}
        >
            <>
                <div
                    className={cn(
                        'duration-250 absolute left-0 h-full rounded-[inherit] transition-all',
                        gradient[index % options.length]
                    )}
                    onTransitionEnd={() => {
                        onTransitionEnd?.(index)
                    }}
                    style={{
                        width: buttonWidth,
                        left,
                    }}
                />
                {options.map((option, index) => {
                    return (
                        <button
                            onClick={() => {
                                setIndex(index)
                                onClick?.(index)
                                return
                            }}
                            className="relative cursor-pointer rounded-3xl p-2 text-[inherit]"
                            key={option}
                            style={{ width: buttonWidth }}
                        >
                            {option}
                        </button>
                    )
                })}
            </>
        </div>
    )
}
export default Toggle
