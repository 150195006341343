import { DeviceType } from '@/app/util/env'
import { PingbackActionType, pingback } from '@giphy/js-analytics'
import { IGif, IImage } from '@giphy/js-types'
import { useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import UserContext from '../../context/user-context'

type AdBannerProps = {
    gif: IGif
    deviceType?: DeviceType
}

const AdBannerGifContainer = styled.div`
    cursor: pointer;
    margin: 6px 0 0;
    video,
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
`

/**
 * Retrieves the ad banner URL, rendition, and file type from the given GIF.
 * @param gif The GIF object.
 * @returns An object containing the URL, rendition, and file type of the ad banner.
 */
const getAdBannerData = (gif: IGif, deviceType?: DeviceType) => {
    /*
     * Workaround for asserting the existence of a source
     * image inside the images array because it is not exposed
     * in the web-sdk.
     */
    const extendedGif = gif as IGif & {
        images: {
            source?: IImage
        }
    }

    const rendition = deviceType === 'desktop' ? 'source' : 'original'
    const url = extendedGif.images[rendition]?.url
    if (!url) throw new Error(`Ad Banner: missing ${rendition} rendition for ${gif.id}`)

    const parsed = new URL(url)
    const fileType = parsed.pathname.endsWith('.mp4') ? 'video' : 'image'

    return { url, fileType, rendition }
}

const AdBanner = ({ gif, deviceType }: AdBannerProps) => {
    const { user } = useContext(UserContext)
    const { ref } = useInView({
        threshold: 1,
        triggerOnce: true,
        onChange: (inView: boolean) => {
            if (!inView) return
            trackAction('SEEN')
        },
    })

    const { url, fileType, rendition } = getAdBannerData(gif, deviceType)

    const trackAction = (actionType: PingbackActionType) => {
        pingback({
            userId: user ? user.id : undefined,
            actionType,
            analyticsResponsePayload: gif.analytics_response_payload,
        })
    }

    const onClickHandler = () => {
        trackAction('CLICK')
        if (gif.bottle_data?.tdata?.click_out_url) {
            window.location.href = gif.bottle_data.tdata.click_out_url
        }
    }

    const onMouseOverHandler = () => {
        trackAction('HOVER')
    }

    const isVideo = fileType === 'video'

    /*
     * Using the source gif is not recommended because its size will be a bad experience
     * for the user due to loading times and should be handled as an error on tracks.js.
     */
    if (rendition === 'source' && !isVideo) {
        console.error(`Ad Banner: using a GIF for ${gif.id}`)
    }

    return (
        <AdBannerGifContainer ref={ref}>
            {isVideo ? (
                <video
                    src={url}
                    title="Home banner"
                    onMouseOver={onMouseOverHandler}
                    onClick={onClickHandler}
                    autoPlay
                    loop
                    muted
                />
            ) : (
                <img src={url} alt="Home banner" onMouseOver={onMouseOverHandler} onClick={onClickHandler} />
            )}
        </AdBannerGifContainer>
    )
}

export default AdBanner
