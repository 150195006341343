'use client'
import NavigateLeftIcon from '@/app/icons/navigate-left'
import NavigateRightIcon from '@/app/icons/navigate-right'
import { getArtists } from '@/app/util/channel-api'
import { IChannel } from '@giphy/js-types'
import { Gif as Gif_ } from '@giphy/react-components'
import { getChannel, getCreatorOfGif, setGADataLayer } from 'analytics'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import useFetchData from 'ui/src/hooks/use-fetch-data'

import CutoutAttribution from '../cutout-attribution'

const Gif = styled(Gif_)`
    position: absolute;
    picture {
        width: 512px;
        height: 342px;
        img {
            object-fit: cover;
        }
    }
`

const Nav = styled.div`
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    top: 0;
    svg {
        filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.7));
    }
`

const NavLeft = styled(Nav)`
    position: absolute;
    left: 0;
`

const NavRight = styled(Nav)`
    position: absolute;
    right: 0;
`

const Container = styled.div``

type Props = {
    width: number
    height: number
    className?: string
    initialData?: IChannel[]
}

const fetchArtists = async (offset: number) => getArtists({ offset, limit: 3 })
const maxIndex = 50
const fetchTimer = 5000
const ArtistTV = ({ width, height, className = '', initialData = [] }: Props) => {
    const { ref, inView } = useInView({})
    const [index, _setIndex] = useState(0)
    const hasLooped = useRef(false)
    const setIndex = (index: number) => {
        // reset to 0 and set haslooped so we show the arrow
        if (index > maxIndex) {
            hasLooped.current = true
            _setIndex(0)
        } else if (index < 0) {
            _setIndex(maxIndex)
        } else {
            _setIndex(index)
        }
    }
    const [autoplayDisabled, setAutoplayDisabled] = useState<boolean>(false)
    const { gifs, doFetch, doneFetching } = useFetchData<IChannel>({
        initialData: initialData,
        fetchData: fetchArtists,
    })
    useEffect(() => {
        if (initialData.length === 0) {
            doFetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (!autoplayDisabled) {
            const interval = setInterval(async () => {
                if (inView) {
                    if (gifs[index + 1]) {
                        setIndex(index + 1)
                    } else {
                        await doFetch()
                        setIndex(index + 1)
                    }
                }
            }, fetchTimer)
            return () => clearInterval(interval)
        }
    }, [index, gifs, inView, autoplayDisabled, doFetch])

    const makeGif = (channel: IChannel) => {
        return (
            <Container ref={ref} key={channel.id} className={className} style={{ width, height }}>
                <Gif
                    gif={channel.featured_gif}
                    height={height}
                    width={width}
                    backgroundColor={'rgba(0,0,0,0)'}
                    overlay={({ isHovered }) => {
                        return <CutoutAttribution canViewForHire={true} user={channel.user} isHovered={isHovered} />
                    }}
                />
                {(index > 0 || hasLooped.current) && (
                    <NavLeft onClick={() => setIndex(index - 1)}>
                        <NavigateLeftIcon width={12} />
                    </NavLeft>
                )}
                {!doneFetching && (
                    <NavRight
                        onClick={async () => {
                            setAutoplayDisabled(true)
                            if (gifs[index + 1]) {
                                setIndex(index + 1)
                            } else {
                                await doFetch()
                                setIndex(index + 1)
                            }
                            setGADataLayer({
                                event: 'general_navigation',
                                options: {
                                    navElement: 'artist carousel',
                                    channel: getChannel(channel),
                                    creator: getCreatorOfGif(channel.featured_gif),
                                    link_url: `${window.location.origin}/${channel.user.username}`,
                                },
                            })
                        }}
                    >
                        <NavigateRightIcon width={12} />
                    </NavRight>
                )}
            </Container>
        )
    }
    return <Container className={className}>{gifs[index] && <div>{makeGif(gifs[index])}</div>}</Container>
}

export default ArtistTV
