'use client'

import UserContext from '@/context/user-context'
import { setGADataLayer } from 'analytics'
import { useContext, useEffect, useRef, useState } from 'react'
import { StickerTakeoverData } from '.'

type Props = {}

const stickerCount = 6 // the number of stickers to show, each sticker gets a column, inside the column there is a random x-axis shift
const stickerSize = 100 // the size of the sticker, each sticker gets scaled randomly between 1 and 1.5
const maxDelay = 8 // the start animation is delayed, making the stickers stagger in

const getNumBetween = (min: number, max: number) => Math.random() * (max - min) + min
// random sticker id
const getId = (ids: string[]): string => ids[Math.floor(Math.random() * ids.length)]
// how long it takes to fall
const getDuration = () => getNumBetween(4, 12)
// scale between 1 and 1.5
const getSize = () => getNumBetween(1, 1.5)

const Sticker = ({
    columnWidth,
    columnStart,
    ids,
}: { columnWidth: string; index: number; columnStart: string } & StickerTakeoverData) => {
    const animationDuration = useRef(getDuration())
    const scale = useRef(getSize())
    const [count, setCount] = useState(0)
    const id = useRef(ids[Math.floor(Math.random() * ids.length)])
    // random x shift within column
    const shift = `${Math.floor(Math.random() * 50)}%`
    return (
        <div
            key={count}
            className="absolute animate-[drop_ease-in-out_forwards]"
            style={{
                animationDuration: `${animationDuration.current}s`,
                animationDelay: `${Math.random() * maxDelay}s`,
                top: `-${stickerSize * scale.current}px`,
                left: columnStart,
                width: `${columnWidth}`,
                height: `calc(100% + ${stickerSize}px)`,
            }}
            onAnimationEnd={() => {
                animationDuration.current = getDuration()
                id.current = getId(ids)
                scale.current = getSize()
                setCount(count + 1)
            }}
        >
            <img
                onClick={() => {
                    setGADataLayer({
                        event: 'attribution_open_click',
                        options: {
                            content: {
                                contentId: id.current,
                                contentType: 'sticker',
                            },
                        },
                    })
                }}
                src={`https://media.giphy.com/media/${id.current}/giphy.webp`}
                width={stickerSize}
                height={stickerSize}
                className="pointer-events-auto relative"
                style={{
                    left: shift,
                    transform: `scale(${scale.current})`,
                }}
                alt=""
            />
        </div>
    )
}
const Stickers = ({ ids }: Props & StickerTakeoverData) => {
    const stickers = new Array(stickerCount).fill(0)
    const { user: _user } = useContext(UserContext)
    useEffect(() => {
        setGADataLayer({
            event: 'sticker_takeover_view',
        })
    }, [])
    return (
        <div className="z-modal pointer-events-none fixed inset-0">
            {stickers.map((_, index) => (
                <Sticker
                    ids={ids}
                    key={index}
                    columnStart={`${Math.floor((100 / stickers.length) * index)}%`}
                    columnWidth={`${100 / stickers.length}%`}
                    index={index}
                />
            ))}
        </div>
    )
}
export default Stickers
