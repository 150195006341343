import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { desktopWidth } from 'ui/src/constants'
import { desktop } from 'ui/src/css'
import { Banner as BannerData, isValidActiveBanner } from '../app/util/banner'
import UAParserContext from '../context/ua-parser'
import AdBanner from './homepage/ad-banner'

const BannerContainer = styled.div`
    height: 22vw;
    ${desktop(css`
        height: 96px;
        width: ${desktopWidth}px;
    `)}
`

const Img = styled.img`
    object-fit: cover;
    height: 100%;
    width: 100%;
    ${desktop(css`
        object-fit: cover;
    `)}
`

type Props = { banner: BannerData }

const Banner = ({ banner }: Props) => {
    const { deviceType } = useContext(UAParserContext)

    const isAdBanner = !isValidActiveBanner(banner)
    return isAdBanner ? (
        <AdBanner gif={banner} deviceType={deviceType} />
    ) : (
        <BannerContainer
            onClick={() => {
                location.href = banner.link
            }}
        >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <Img src={deviceType === 'desktop' ? banner.image : banner.image_mobile} alt={banner.alt_text} />
        </BannerContainer>
    )
}

export default Banner
