import { IGif } from '@giphy/js-types'
import { Gif as _Gif, GifOverlayProps, Loader, Grid as SDKGrid, VideoOverlay } from '@giphy/react-components'
import { useContext, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import useWindowSize from 'react-use/lib/useWindowSize'
import styled from 'styled-components'
import { desktop } from 'ui/src/css'
import useFetchData from 'ui/src/hooks/use-fetch-data'
import UAParserContext from '../../context/ua-parser'
import { relativeGifClick } from '../../util/url'
import useClientRender from '../../util/use-client-render'
import Attribution from '../attribution'
import { Container } from './sc'
import { usePageViewTracking } from './use-page-view-tracking'

const Gif = styled(_Gif)`
    position: relative;
    video {
        object-fit: cover;
    }
`

const Item = styled.div`
    margin-bottom: 10px;
`
const Title = styled.div`
    font-size: 17px;
    font-weight: bold;
    line-height: 2;
    overflow: hidden;
    margin: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`

type Props = Pick<SDKGrid['props'], 'initialGifs' | 'fetchGifs'> & {
    gaTrackingUrl: (page: number) => string
    gridWidth?: number
    columns?: number
    hideAttribution?: boolean
}

/**
 * A fixed size grid with titles
 */
const VideoGrid = ({
    initialGifs = [],
    fetchGifs,
    gaTrackingUrl,
    gridWidth,
    columns = 2,
    hideAttribution = false,
}: Props) => {
    const canRender = useClientRender()
    const loaderConfig = useMemo(() => (canRender ? { rootMargin: '0px 0px 250px 0px' } : undefined), [canRender])
    const { ref, inView } = useInView(loaderConfig)
    const { doneFetching, gifs } = useFetchData<IGif>({
        initialData: initialGifs,
        fetchData: fetchGifs,
        triggerFetch: inView,
    })
    const { deviceType } = useContext(UAParserContext)
    usePageViewTracking(gaTrackingUrl, gifs.length)
    let { width } = useWindowSize()

    // no way to do grids on mobile yet since we don't know window width on server
    // go by server ua parsing
    let isDesktop = deviceType === 'desktop'
    // switch to window width on client
    if (canRender) {
        isDesktop = width >= desktop.breakpointWidth
    }
    // fetch gifs in advance of 250px so you don't see the loader if you slow scroll
    const spacing = 6
    const defaultWidth = isDesktop ? 340 : width / columns - spacing
    const columnWidth = gridWidth ? gridWidth / columns - (spacing * columns - 1) : defaultWidth

    return loaderConfig ? (
        <>
            <Container className="" style={{ width: gridWidth }}>
                {gifs
                    ? gifs.map((gif: IGif) => {
                          return (
                              <Item key={gif.id} style={{ width: columnWidth }}>
                                  <Gif
                                      overlay={(props: GifOverlayProps) => (
                                          <VideoOverlay {...props} width={columnWidth} />
                                      )}
                                      gif={gif}
                                      width={columnWidth}
                                      height={(columnWidth * 9) / 16}
                                      onGifClick={(gif, event) => {
                                          event.stopPropagation()
                                          event.preventDefault()
                                          relativeGifClick(gif, event)
                                      }}
                                  />
                                  <Title>{gif.title}</Title>
                                  {!hideAttribution && !!gif.user && <Attribution user={gif.user} />}
                              </Item>
                          )
                      })
                    : null}
            </Container>
            {!doneFetching && (
                <div ref={ref}>
                    <Loader />
                </div>
            )}
        </>
    ) : null
}

export default VideoGrid
